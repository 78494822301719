<script lang="ts">
	import Icon from "@components/common/Icon.svelte";
	import type { EmblaCarouselType } from "embla-carousel";
	import chevron from "@core/assets/icons/chevron.svg?raw";

	const { api, slideCount }: { api: EmblaCarouselType | undefined; slideCount: number } = $props();

	let pointContainer = $state<HTMLDivElement | null>(null);
	const pointButtons = $state<HTMLButtonElement[]>([]);
	let current = $state(api?.slidesInView() ?? []);
	$effect(() => {
		if (!api) {
			return;
		}

		// Ignore duplicated slides created by our Carousel.svelte
		current = api.slidesInView().map((inViewIndex) => inViewIndex % slideCount);
		api.on("slidesInView", () => {
			// Ignore duplicated slides created by our Carousel.svelte
			current = api.slidesInView().map((inViewIndex) => inViewIndex % slideCount);
		});
	});
	$effect(() => {
		if (!pointContainer || !api) {
			return;
		}

		const middleIndex = current[Math.round(current.length / 2) - 1];
		if (middleIndex !== undefined && pointButtons[middleIndex]) {
			pointContainer.scrollTo({
				behavior: "smooth",
				left: pointButtons[middleIndex].offsetLeft - pointContainer.offsetLeft - pointContainer.clientWidth / 2,
			});
		}
	});

	/* eslint-disable @typescript-eslint/no-confusing-void-expression */
</script>

{#snippet arrowButton(direction: "prev" | "next")}
	<button
		aria-label={direction === "prev" ? "Předchozí" : "Následující"}
		class="group rounded-full aspect-square text-white bg-fstlight-100 hover:bg-fstgreen h-10 transition-colors"
		onclick={(): void => {
			if (direction === "prev") {
				api?.scrollPrev();
			} else {
				api?.scrollNext();
			}
		}}
	>
		<Icon
			icon={chevron}
			class={{ "h-6 text-fstgrey-200 group-hover:text-white": true, "rotate-180": direction === "prev" }}
		/>
	</button>
{/snippet}

<div class="flex gap-8 items-center rounded-full bg-white p-2 mx-auto max-w-full overflow-auto relative">
	{@render arrowButton("prev")}
	<div bind:this={pointContainer} class="flex gap-4 items-center overflow-auto no-scrollbar touch-none">
		{#each { length: slideCount } as _, index (index)}
			{@const active = current.includes(index)}

			<button
				bind:this={pointButtons[index]}
				class="aspect-square h-[0.625rem] bg-fstlight-200 rounded-full"
				class:bg-fstlight-200={!active}
				class:bg-fstonyx={active}
				aria-label="Přejít na {index + 1} slide"
				onclick={(): void => {
					api?.scrollTo(index);
				}}
			></button>
		{/each}
	</div>
	{@render arrowButton("next")}
</div>
